import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/blog-card"

const TagTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark

  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  const title = `${tag} Post${totalCount === 1 ? "" : "s"} - Celantur Blog`

  const description = `Read ${totalCount} post${
    totalCount === 1 ? "" : "s"
  } about "${tag}"`

  return (
    <Layout>
      <SEO title={title} description={description} />

      <section className="container pt-5 my-5 text-justify">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center mb-3">
            <h1 className="display-3 font-weight-bold text-capitalize">
              {tag} Blog Posts
            </h1>
            <p className="font-size-lg text-muted">{tagHeader}</p>
            <Link
              to="/blog/"
              role="button"
              className="btn btn-sm btn-success-soft my-2"
            >
              Display all Posts
            </Link>
          </div>
        </div>
      </section>

      <section className="container my-5">
        <div>
          {edges.map(({ node, index }) => {
            const { title, path, teaser, author, date } = node.frontmatter
            return (
              <BlogCard
                title={title}
                path={path}
                teaser={teaser}
                author={author}
                date={date}
                index={index}
                key={index}
              />
            )
          })}
        </div>
      </section>

      <section className="container my-5 text-center">
        <Link to="/blog/" role="button" className="btn btn-lg btn-success my-2">
          Go to Blog
        </Link>
      </section>
    </Layout>
  )
}

TagTemplate.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              path: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default TagTemplate

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            teaser
            author
            date
          }
        }
      }
    }
  }
`
